import React from "react";
import "./Skills.css";
import { Fade } from "react-reveal";

export default function Aboutus(props) {
  const theme = props.theme;
  return (
    <div className="main" id="skills">
      <div className="skills-header-div">
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            About Me
          </h1>
          <div className="greeting-text-div">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <p
                className="greeting-text-p subTitle"
                style={{ color: theme.secondaryText }}
              >
                <br />
                A.K.A
                <span style={{ color: theme.accentColor }}>
                  {" "}“THE BODYBUILDING ALCHEMIST”
                </span>
                <br />
                You know me as one of the most prolific bodybuilding and fitness
                coach in the country.
                <br />
                In Past 400 Days I secure 100+ Gold medals, 50+ Silver medals,
                40+ Bronze medals, 12
                <span style={{ color: theme.accentColor }}> OVERALL WINS</span>,
                1
                <span style={{ color: theme.accentColor }}> IFBB PRO CARD</span>{" "}
                (2 IN TOTAL) and now working with 3
                <span style={{ color: theme.accentColor }}> IFBB PROS</span>. I
                am leading
                <span style={{ color: theme.accentColor }}>
                  {" "}
                  “TEAM HEAVY DUTY”{" "}
                </span>
                with my team of assistant coaches where we are coaching 210+
                currently active athletes.
                <br />
                As a Fitness Coach I am heading
                <span style={{ color: theme.accentColor }}>
                  {" "}
                  “PROJECT FIT HUMAN”{" "}
                </span>
                where I am coaching 110+ lifestyle clients with my team of
                assitant coaches and we are yet to complete 1 year for
                <span style={{ color: theme.accentColor }}>
                  {" "}
                  PROJECT FIT HUMAN
                </span>
                .
                <br />
                <span style={{ color: theme.accentColor }}>FINANCIALLY</span>,
                in september 2021, I recorded my first
                <span style={{ color: theme.accentColor }}>
                  {" "}7-FIGURE MONTH EXCLUSIVELY FROM COACHING ONLY{" "}
                </span>
                (NO SUPPLEMENT SALES OR COMMISIONS INCLUDED) From that time, we
                are continously breaking that 7-figure barrier every month.
                <br />
                NOW AT
                <span style={{ color: theme.accentColor }}>
                  {" "}“FITNESS BUSINESS UNIVERSITY”{" "}
                </span>
                I am going to break down the entire process of
                <span style={{ color: theme.accentColor }}>
                  {" "}
                  SCALING UP YOUR FITNESS BUSINESS “STEP BY STEP & CHAPTER BY
                  CHAPTER & COURSE BY COURSE"
                </span>
                <br />
                CLICK THE SECTION OF 
                <span style={{ color: theme.accentColor }}>
                  {" "}“FITNESS BUSINESS UNIVERSITY”{" "}
                </span>
                
                 BELOW TO
                GET MORE INSIGHTS.
              </p>
            </div>
          </div>
        </Fade>
      </div>
      {/* <SkillSection theme={theme} /> */}
    </div>
  );
}
