import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <Fade>
        <p className="footer-text" style={{ color: props.theme.secondaryText }}>
          Designed by <a className="footer-1" href="https://deltainfox.com" target="_blank" rel="noreferrer">Delta Infox</a>
        </p>
      </Fade>
    </div>
  );
}
