import React from "react";
import heavy from '../../assests/logo/heavy.png'
import fit from '../../assests/logo/fit.png'


export default function CloudInfraImg(props) {
  return (
    <>
    <img src={heavy} alt="" />
  <img src={fit} alt="" />
    </>
  );
}
