import React from "react";
import cpa from '../../assests/logo/cpa.png'
import tuff from '../../assests/logo/Tuff.png'


export default function DataScienceImg(props) {
  return (
   <>
   <img src={tuff} alt="" />
   <img src={cpa} alt="" />
   </>
  );
}
