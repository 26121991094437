import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";
import ExperienceImg from "./ExperienceImg";

function Experience(props) {
  const theme = props.theme;
  console.log(props.setTheme);
  return (
    <div className="experience-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-experience">
        <Fade bottom duration={2000} distance="40px">
          <div className="experience-heading-div">
       
            <div className="experience-heading-text-div">
              <h1
                className="experience-heading-text"
                style={{ color: theme.text }}
              >
                Online Coaching
              </h1>
         
              <p
                className="experience-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
               I am leading “TEAM HEAVY DUTY” with my team of assistant coaches where we are coaching 210+ currently active athletes.
As a Fitness Coach I am heading “PROJECT FIT HUMAN” where I am coaching 110+ lifestyle clients with my team of assitant coaches and we are yet to complete 1 year for PROJECT FIT HUMAN.
              </p>
            </div>
          </div>
        </Fade>
      </div>
      {/* <ExperienceAccordion sections={experience["sections"]} theme={theme} /> */}
      {/* <div style={{marginTop: "150px"}} class="container">
          <div
          
            id="development"
            target="_blank"
            class="section" rel="noreferrer"
          >
            <div class="content">
              <h1>Project Fit Human</h1>
            

            </div>
            <div class="overlay"></div>
          </div>
          <div
           
            id="coding"
            class="section" rel="noreferrer"
          >
            <div class="content">
              <h1>Team Heavy Duty</h1>
            </div>
            <div class="overlay"></div>
          </div>
        </div> */}




<div className="main-div-edu">
        <div className="cpa-card">
          <img
            className="logo-cpa-edu"
            src={require("../../assests/logo/fit.png")}
            alt=""
          />
          <div className="right-h-edu">
          <h1 className="card-head">Fit Human</h1>
            <p className="">
            THE MOST CONVENIENT AND AFFORDABLE LIFESTYLE AND TRANSFORMATION COACHING.
            </p>
              <a
              className="a-tab-nav"
                  target="_blank"
              href="http://wa.me/+918837651715">
            <button className="btn-shop-fit">
                Buy Now
            </button>
              </a>
          
          </div>
        </div>
        <div className="tuff-card">
          <img
            className="logo-cpa-edu"
            src={require("../../assests/logo/heavy.png")}
            alt=""
          />
          <div className="right-h-edu">
            <h1 className="card-head">Heavy Duty</h1>
            <p className="">
THE FINEST CONTEST PREP COACHING PROGRAM.
            </p>
              <a
              className="a-tab-nav"
                  target="_blank"
                  href="http://wa.me/+918837651715">
            <button className="btn-shop-heavy">
                Buy Now
            </button>
              </a>
          
          </div>
        </div>
      </div>


      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Experience;
