import React, { useRef, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import BlogsImg from "./BlogsImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";
import { style } from "glamor";
import image1 from "../../assests/img1.png";
import { toast } from "react-hot-toast";
import emailjs from "@emailjs/browser";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;

function Contact(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });

  const form = useRef();

  const [name, setName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qx2y2oe",
        "template_b2v3ps1",
        form.current,
        "CcHXGEqyOXiYvVUPt"
      )
      .then(
        (result) => {
          // console.log(result.text);
        },
        (error) => {
          // console.log(error.text);
        }
      );

    toast.success("Send Successfully");
    setName("");
    setemail("");
    setphone("");
    setmessage("");
  };

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <Fade bottom duration={1000} distance="40px">
          <div className="contact-heading-div">
            <div className="contact-heading-img-div">
              <img className="profile-pic" src={image1} alt="" />
            </div>
            <div className="contact-heading-text-div">
              <h1
                className="contact-heading-text"
                style={{ color: theme.text }}
              >
                My Social Media
              </h1>
              <p
                className="contact-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {ContactData["description"]}
              </p>
              <SocialMedia />
              <br />
              <br />
            </div>
          </div>
        </Fade>
        <Fade bottom duration={1000} distance="40px">
          <h1 className="contact-heading-text" style={{ color: theme.text }}>
            OR
          </h1>
        </Fade>
        <Fade bottom duration={1000} distance="40px">
          <div className="blog-heading-div">
            <div className="blog-heading-text-div">
              <h1 className="blog-heading-text" style={{ color: theme.text }}>
                Contact Me
              </h1>
              <form ref={form} onSubmit={sendEmail} className="inputfieldscontact">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  className="inputfieldcontact"
                />
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="inputfieldcontact"
                />
                <input
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  type="text"
                  name="number"
                  id="number"
                  placeholder="Phone Number"
                  className="inputfieldcontact"
                />
                <textarea
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder="Message..."
                  className="inputfieldcontact1"
                ></textarea>
                  <div className="blogsite-btn-div">
                <button
                type="submit"
                disabled={!name || !email || !phone || !message} 
                value="Send"
                  {...styles}
                  className="general-btn"
                >
                  Send Message
                </button>
              </div>
              </form>
            
            </div>
            <div className="blog-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
          </div>
        </Fade>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
