import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import EducationImg from "./EducationImg";
import "./EducationComponent.css";
import { Fade } from "react-reveal";

function Education(props) {
  const theme = props.theme;
  return (
    <div className="education-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="main-div-edu">
        <div className="cpa-card">
          <img
            className="logo-cpa-edu"
            src={require("../../assests/logo/cpa.png")}
            alt=""
          />
          <div className="right-h-edu">
          <h1 className="card-head">Contest Prep Academy</h1>
            <p className="">
            THE MOST ELABORATED BODYBUILDING STUDY EVER
            </p>
              <a
              className="a-tab-nav"
                  target="_blank"
              href="https://www.deltainfox.com/FitnessEdu/ContestPrepAcademy">
            <button className="btn-shop-cpa">
                Buy Now
            </button>
              </a>
          
          </div>
        </div>
        <div className="tuff-card">
          <img
            className="logo-cpa-edu"
            src={require("../../assests/logo/Tuff.png")}
            alt=""
          />
          <div className="right-h-edu">
            <h1 className="card-head">Tuff Trainer</h1>
            <p className="">
        Teaching the art of fitness (nutrition & training) and business
            </p>
              <a
              className="a-tab-nav"
                  target="_blank"
              href="https://www.deltainfox.com/FitnessEdu/TuffTrainer">
            <button className="btn-shop-tuff">
                Buy Now
            </button>
              </a>
          
          </div>
        </div>
      </div>
        <div style={{ opacity: " 0" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur
          laborum sunt voluptates reprehenderit consequatur! A amet accusamus
          eius, quia ea at doloremque ipsam porro laudantium, ducimus, natus
          dolor consectetur recusandae.
        </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Education;
